import { NotifyBouncedCheckKey, ReceiptKey } from './keys';

export interface Receipt {
  [ReceiptKey.Id]: string;
  [ReceiptKey.ReceiptNumber]?: string;
  [ReceiptKey.CaseNumber]?: string;
  [ReceiptKey.TDICaseNumber]?: string;
  [ReceiptKey.TDISFCaseNumber]?: string;
  [ReceiptKey.FundType]?: ReceiptFundType;
  [ReceiptKey.Employer]?: string;
  [ReceiptKey.ReceiptStatus]?: string;
  [ReceiptKey.ReceiptStatusDate]?: string;
  [ReceiptKey.HRSSection]?: string;
  [ReceiptKey.PurposeOfReceipt]?: string;
  [ReceiptKey.ModeOfPayment]?: string;
  [ReceiptKey.CreatedBy]?: string;
  [ReceiptKey.LastModifiedBy]?: string;
  [ReceiptKey.PaymentFor]?: string;
  [ReceiptKey.AmountCollected]?: string | number;
  [ReceiptKey.CollectedFrom]?: string;
  [ReceiptKey.Payer]?: string;
  [ReceiptKey.ControlNumber]?: string;
  [ReceiptKey.AmountPaid]?: string;
  [ReceiptKey.UpdatedAt]?: string;
  [ReceiptKey.ExpenseGeneralLedgerAccountNumber]?: string;
  [ReceiptKey.ExpenseGeneralLedgerAccountId]?: string;
  [ReceiptKey.AmountReceived]?: string;
  [ReceiptKey.Branch]?: string;
  [ReceiptKey.CaseId]?: string;
  [ReceiptKey.EmployerId]?: string;
  [ReceiptKey.ClaimantId]?: string;
  [ReceiptKey.ClaimantName]?: string;
  [ReceiptKey.Employees]?: string;
  [ReceiptKey.EmployerLaborNumber]?: string;
  [ReceiptKey.LaborNumber]?: string;
  [ReceiptKey.ExpenseGeneralLedgerDescription]?: string;
  [ReceiptKey.Violator]?: string;
  [ReceiptKey.CreatedAt]?: string;
  [ReceiptKey.LastModifiedDate]?: string;
  [ReceiptKey.BalanceAmount]?: string;
  [ReceiptKey.Remarks]?: string;
  [ReceiptKey.PayerType]?: string;
  [ReceiptKey.Other]?: string;
  [ReceiptKey.EmployerName]?: string;
  [ReceiptKey.OtherRemark]?: string;
  [ReceiptKey.CaseEmployerId]?: string;
  [ReceiptKey.CaseEmployer]?: string;
}

export enum BranchKey {
  Hearings = 'HEARINGS',
  PlanAcceptanceBranch = 'PLAN ACCEPTANCE BRANCH',
  RecordsClaims = 'RECORDS & CLAIMS',
  Other = 'OTHER',
}

export enum ReceiptFundType {
  GF = 'GENERAL_FUND',
  PSF = 'PSF',
  TDISF = 'TDI_SF',
  Disbursement = 'WC_SCF',
}

export enum PurposeOfReceiptKey {
  ReimbursementForBenefitsPaid = 'REIMBURSEMENT FOR BENEFIT PAID',
  Penalty = 'PENALTY',
  EmployeesAreUnableToBeLocated = 'EMPLOYEES ARE UNABLE TO BE LOCATED',
  ExcessContribution = 'EXCESS CONTRIBUTION',
  Other = 'OTHER',
}

export enum PayerTypeKey {
  InsuranceCarrier = 'INSURANCE_CARRIER',
  Physician = 'PHYSICIAN',
  Other = 'OTHER',
}

export enum ModeOfPaymentKey {
  PartialPayment = 'PARTIAL',
  PaymentInFull = 'FULL',
}

export enum HrsSectionKey {
  S38652 = 'Section 386-52 Employee overpayment',
  S38656 = 'Section 386-56 Employer noncompliance',
  S38631 = 'Section 386-31 Failure to make correct or timely benefit payments or terminate such benefits without approval or statutory cause',
  S38695 = 'Section 386-95 Failure to file reports of injuries or other reports as required.',
  S38696 = 'Section 386-96 (1) Penalty against physician for failure to either submit a report or file a timely report. (2) Penalty against employer/insurance carrier for failure to furnish medical reports to claimant or allow inspection and copying of transcripts of depositions of medical witnesses.',
  S386123 = 'Section 386-123 Failure to obtain security for payment of compensation, Other',
  S39348 = 'Section 393-48',
  S39311 = 'Section 393-11',
  S39312 = 'Section 393-12',
  S39313 = 'Section 393-13',
  S39315 = 'Section 393-15',
  S121241 = 'Section 12-12-41 of administrative rules',
  S121242 = 'Section 12-12-42 of administrative rules',
  S39245 = 'Section 392-45 WC subrogation',
  S39246 = 'Section 392-46 Third party lien',
  S39265 = 'Section 392-65 Employer noncompliance',
  S39242 = 'Section 392-42',
  S39269 = 'Section 392-69',
  S39247 = 'Section 392-47',
  S392101 = 'Section 392-101',
  S39243g = 'Section 392-43(g)',
  S39243f = 'Section 392-43(f) Excess contributions of employees',
  S39242f = 'Section 392-42(f) Employees are unable to be located',
}

export enum ReceiptStatusKey {
  NewReceipt = 'NEW',
  SentToFiscal = 'SENT TO FISCAL',
  Complete = 'COMPLETE',
}

export interface NotifyBouncedCheck {
  id: string;
  [NotifyBouncedCheckKey.RecipientUsers]: Array<string>;
  [NotifyBouncedCheckKey.RecipientProfiles]: Array<string>;
  [NotifyBouncedCheckKey.IsAllUser]: boolean;
  [NotifyBouncedCheckKey.IsAllProfile]: boolean;
}
