import { WCCoveragesTypes } from '../WCCoverages';
import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { WCInsurancePoliciesKey } from './key';
import { WCInsurancePoliciesInterface } from './types';

export const toWCIP = (
  data: PaginationResponseType<any>
): PaginationResponseType<WCInsurancePoliciesInterface> => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    // ==================POLICY INFORMATION====================//
    [WCInsurancePoliciesKey.MasterCoverageEffectiveDate]: hasMasterCoverage(item?.coverages)
      ? item?.masterCoverage?.effectiveDate
      : '',
    [WCInsurancePoliciesKey.AgentMasterCoverageExpirationDate]: hasMasterCoverage(item?.coverages)
      ? item?.masterCoverage?.expirationDate
      : '',
    [WCInsurancePoliciesKey.HasMasterCoverage]: hasMasterCoverage(item?.coverages),
    //==============Insurance Carrier ================//
    [WCInsurancePoliciesKey.InsuranceCarrierName]: item?.insuranceCarrier?.accountName,
    [WCInsurancePoliciesKey.InsuranceCarrier]: item?.insuranceCarrier?.accountName,
    [WCInsurancePoliciesKey.CarrierID]: item?.insuranceCarrier?.carrierId,
    [WCInsurancePoliciesKey.Captive]: item?.insuranceCarrier?.isCaptiveCarrier ? true : false,
    // ==================MASTER EMPLOYER INFORMATION====================//
    [WCInsurancePoliciesKey.Employer]: hasMasterCoverage(item?.coverages)
      ? item?.masterCoverage?.employer?.accountName
      : '',
    [WCInsurancePoliciesKey.EmployerStatus]: hasMasterCoverage(item?.coverages)
      ? item?.masterCoverage?.employer?.dcdStatus
      : '',
    [WCInsurancePoliciesKey.DOLNumber]: hasMasterCoverage(item?.coverages)
      ? item?.masterCoverage?.employer?.laborNumber
      : '',
    [WCInsurancePoliciesKey.NumberOfEmployeesInHawaiiFilterKey]: hasMasterCoverage(item?.coverages)
      ? item?.masterCoverage?.employer?.numOfEmployees
      : '',
    [WCInsurancePoliciesKey.ProfessionalEmployerOrganization]:
      hasMasterCoverage(item?.coverages) && item?.masterCoverage?.employer?.isPEO ? true : false,
    // ==================Parent Company====================//
    [WCInsurancePoliciesKey.ParentCompany]: item?.parentCompany?.accountName,
    [WCInsurancePoliciesKey.ParentCompanyAddress]: item?.parentCompany?.street,
    // ==================PolicyType SI====================//
    [WCInsurancePoliciesKey.IndividualResponsibleSIProgram]:
      item?.individualResponsibleForSiProgram?.contactName,
    [WCInsurancePoliciesKey.TitlePolicyTypeSI]: item?.individualResponsibleForSiProgram?.title,
    [WCInsurancePoliciesKey.PhoneNumberPolicyTypeSI]:
      item?.individualResponsibleForSiProgram?.phone,
    [WCInsurancePoliciesKey.AddressPolicyTypeSI]: item?.individualResponsibleForSiProgram?.street,
    [WCInsurancePoliciesKey.EmailAddressPolicyTypeSI]:
      item?.individualResponsibleForSiProgram?.email,
    //============== S ADMINISTRATION (ADMIN) ================//
    [WCInsurancePoliciesKey.NameOfAdministrator]: item?.administrator?.contactName,
    [WCInsurancePoliciesKey.AdministratorAddress]: item?.administrator?.street,
    [WCInsurancePoliciesKey.AdministratorPhoneNumber]: item?.administrator?.phone,
    [WCInsurancePoliciesKey.AdministratorEmailAddress]: item?.administrator?.email,
    //==============POPULATED CONTACT ================//
    [WCInsurancePoliciesKey.Name]: item?.contact?.contactName,
    [WCInsurancePoliciesKey.TitleAdmin]: item?.contact?.title,
    [WCInsurancePoliciesKey.AddressAdmin]: item?.contact?.street,
    [WCInsurancePoliciesKey.PhoneAdmin]: item?.contact?.phone,
    [WCInsurancePoliciesKey.EmailAddressAdmin]: item?.contact?.email,
    [WCInsurancePoliciesKey.CreatedBy]: item?.createdBy
      ? `${item?.createdUser?.firstName} ${item?.createdUser?.lastName}`
      : null,
    [WCInsurancePoliciesKey.UpdatedBy]: item?.updatedBy
      ? `${item?.updatedUser?.firstName || ''} ${item?.updatedUser?.lastName || ''}`
      : null,

    ...toRelatedAuditData(item),
  })),
});

export const toWCIPDetails = (item) => ({
  ...item,
  [WCInsurancePoliciesKey.CreatedUser]: item?.createdBy
    ? `${item?.createdUser?.firstName} ${item?.createdUser?.lastName}`
    : null,
  [WCInsurancePoliciesKey.UpdatedUser]: item?.updatedBy
    ? `${item?.updatedUser?.firstName || ''} ${item?.updatedUser?.lastName || ''}`
    : null,
  // ==================POLICY INFORMATION====================//
  [WCInsurancePoliciesKey.MasterCoverageEffectiveDate]: hasMasterCoverage(item?.coverages)
    ? item?.masterCoverage?.effectiveDate
    : '',
  [WCInsurancePoliciesKey.AgentMasterCoverageExpirationDate]: hasMasterCoverage(item?.coverages)
    ? item?.masterCoverage?.expirationDate
    : '',
  [WCInsurancePoliciesKey.HasMasterCoverage]: hasMasterCoverage(item?.coverages),
  //==============Insurance Carrier ================//
  [WCInsurancePoliciesKey.InsuranceCarrierName]: item?.insuranceCarrier?.accountName,
  [WCInsurancePoliciesKey.CarrierID]: item?.insuranceCarrier?.carrierId,
  [WCInsurancePoliciesKey.Captive]: item?.insuranceCarrier?.isCaptiveCarrier ? true : false,
  // ==================MASTER EMPLOYER INFORMATION====================//
  [WCInsurancePoliciesKey.Employer]: hasMasterCoverage(item?.coverages)
    ? item?.masterCoverage?.employer?.accountName
    : '',
  [WCInsurancePoliciesKey.EmployerStatus]: hasMasterCoverage(item?.coverages)
    ? item?.masterCoverage?.employer?.dcdStatus
    : '',
  [WCInsurancePoliciesKey.DOLNumber]: hasMasterCoverage(item?.coverages)
    ? item?.masterCoverage?.employer?.laborNumber
    : '',
  [WCInsurancePoliciesKey.NumberOfEmployeesInHawaiiFilterKey]: hasMasterCoverage(item?.coverages)
    ? item?.masterCoverage?.employer?.numOfEmployees
    : '',
  [WCInsurancePoliciesKey.ProfessionalEmployerOrganization]:
    hasMasterCoverage(item?.coverages) && item?.masterCoverage?.employer?.isPEO ? true : false,
  // ==================Parent Company====================//
  [WCInsurancePoliciesKey.ParentCompany]: item?.parentCompany?.accountName,
  [WCInsurancePoliciesKey.ParentCompanyAddress]: item?.parentCompany?.street,
  // ==================PolicyType SI====================//
  [WCInsurancePoliciesKey.IndividualResponsibleSIProgram]:
    item?.individualResponsibleForSiProgram?.contactName,
  [WCInsurancePoliciesKey.TitlePolicyTypeSI]: item?.individualResponsibleForSiProgram?.title,
  [WCInsurancePoliciesKey.PhoneNumberPolicyTypeSI]: item?.individualResponsibleForSiProgram?.phone,
  [WCInsurancePoliciesKey.AddressPolicyTypeSI]: item?.individualResponsibleForSiProgram?.street,
  [WCInsurancePoliciesKey.EmailAddressPolicyTypeSI]: item?.individualResponsibleForSiProgram?.email,
  //==============CLAIMS ADMINISTRATION (ADMIN) ================//
  [WCInsurancePoliciesKey.NameOfAdministrator]: item?.administrator?.contactName,
  [WCInsurancePoliciesKey.AdministratorAddress]: item?.administrator?.street,
  [WCInsurancePoliciesKey.AdministratorPhoneNumber]: item?.administrator?.phone,
  [WCInsurancePoliciesKey.AdministratorEmailAddress]: item?.administrator?.email,
  //==============POPULATED CONTACT ================//
  [WCInsurancePoliciesKey.Name]: item?.contact?.contactName,
  [WCInsurancePoliciesKey.TitleAdmin]: item?.contact?.title,
  [WCInsurancePoliciesKey.AddressAdmin]: item?.contact?.street,
  [WCInsurancePoliciesKey.PhoneAdmin]: item?.contact?.phone,
  [WCInsurancePoliciesKey.EmailAddressAdmin]: item?.contact?.email,
});

export const hasMasterCoverage = (coverages: WCCoveragesTypes[]) => {
  if (!coverages || coverages.length === 0) return false;
  return coverages.some((coverage) => coverage.isMaster);
};
