export enum UploadKey {
  //Document Information
  Id = 'id',
  WCCaseId = 'wcCaseId',
  Insurance = 'insuranceType',
  FundType = 'fundType',
  DocumentCategory = 'documentCategory',
  InsuranceCarrier = 'insuranceCarrierId',
  FinancialYear = 'financialYear',
  DateOfInjury = 'dateOfInjury',
  FillingParty = 'fillingParty',
  DCDFillingDate = 'dcdFillingDate',
  DocumentType = 'documentType',
  DateDocumentReceived = 'dateDocumentReceived',
  DocumentDate = 'documentDate',
  DocumentDescription = 'documentDescription',
  Attachment = 'attachment',
  DocumentTitle = 'documentTitle',
  LinkTo = 'linkTo',
  TDICaseId = 'tdiCaseId',
  TDISFCaseId = 'tdiSFCaseId',
  DecisionId = 'decisionId',
  EmployerId = 'employerId',
  EmployerAuditId = 'employerAuditId',

  //Notification Information
  NotificationRecipientType = 'notificationRecipientType',
  RecipientUsers = 'recipientUserIds',
  IsAllRecipientUsers = 'isAllUser',
  RecipientProfiles = 'recipientProfileIds',
  IsAllRecipientProfiles = 'isAllProfile',
  Size = 'size',
  DocumentName = 'documentName',
  AttachmentURL = 'attachmentURL',
}
