import { HealthCarePlanType } from 'src/queries/HealthCarePlans';
import { PaginationResponseType, toAuditData } from '../helpers';
import { toRelatedAuditData } from './../helpers';
import { PHCCoverageKey } from './keys';
import { PHCCoverage, VALUE_CONTRACTOR_ID_SI } from './types';

export const toPHCCoverage = (data): PaginationResponseType<PHCCoverage> => {
  return {
    ...data,
    data: data.data.map((phcCoverage) => ({
      ...phcCoverage,
      [PHCCoverageKey.HCPlanId]: phcCoverage?.hcPlan?.id,
      [PHCCoverageKey.HealthCarePlanName]: phcCoverage?.hcPlan?.planName,
      [PHCCoverageKey.HCPlanType]: phcCoverage?.hcPlan?.planType,
      [PHCCoverageKey.HCPlanName]: phcCoverage?.hcPlan?.planName,
      [PHCCoverageKey.EmployerName]: phcCoverage?.employer?.accountName,
      [PHCCoverageKey.EmployerId]: phcCoverage?.employer?.id,
      [PHCCoverageKey.DOLNumber]: phcCoverage?.employer?.laborNumber,
      [PHCCoverageKey.HealthCareContractor]: phcCoverage?.hcPlan?.hcContractor?.accountName,
      [PHCCoverageKey.PHCPlanNumber]: phcCoverage?.hcPlan?.planNumber,
      [PHCCoverageKey.HealthCareContractorId]: phcCoverage?.hcPlan?.hcContractorId,
      [PHCCoverageKey.ContractorId]: phcCoverage?.hcPlan?.hcContractor?.carrierId,
      ...toRelatedAuditData(phcCoverage),
    })),
  };
};

export const toPHCCoverageDetail = (phcCoverage) => {
  return {
    ...phcCoverage,
    [PHCCoverageKey.HCPlanId]: phcCoverage?.hcPlan?.id,
    [PHCCoverageKey.HealthCarePlanName]: phcCoverage?.hcPlan?.planName,
    [PHCCoverageKey.HCPlanType]: phcCoverage?.hcPlan?.planType,
    [PHCCoverageKey.HCPlanName]: phcCoverage?.hcPlan?.planName,
    [PHCCoverageKey.EmployerName]: phcCoverage?.employer?.accountName,
    [PHCCoverageKey.EmployerDOLNUmber]: phcCoverage?.employer?.laborNumber,
    [PHCCoverageKey.ContractorId]:
      phcCoverage?.hcPlan?.planType === HealthCarePlanType.SelfInsured
        ? VALUE_CONTRACTOR_ID_SI
        : phcCoverage?.hcPlan?.hcContractor?.carrierId,
    [PHCCoverageKey.EmployerId]: phcCoverage?.employer?.id,
    [PHCCoverageKey.DOLNumber]: phcCoverage?.employer?.laborNumber,
    [PHCCoverageKey.StatusEffectiveDate]: phcCoverage?.hcPlan?.statusEffectiveDate,
    [PHCCoverageKey.HealthCareContractor]:
      phcCoverage?.hcPlan?.planType === HealthCarePlanType.SelfInsured
        ? ''
        : phcCoverage?.hcPlan?.hcContractor?.accountName,
    [PHCCoverageKey.PHCPlanNumber]: phcCoverage?.hcPlan?.planNumber,
    [PHCCoverageKey.HealthCareContractorId]: phcCoverage?.hcPlan?.hcContractorId,
    ...toAuditData(phcCoverage),
  };
};
