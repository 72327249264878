import { formatDateTime } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { WCCoveragesKey } from './keys';
import { WCCoveragesTypes } from './types';

export const toCoverageData = (
  data: PaginationResponseType<any>
): PaginationResponseType<WCCoveragesTypes> => ({
  ...data,
  data: data.data.map((coverage) => ({
    ...coverage,
    [WCCoveragesKey.EmployerName]: coverage?.employer?.accountName,
    [WCCoveragesKey.DOLNumber]: coverage?.employer?.laborNumber,
    [WCCoveragesKey.PolicyNumber]: coverage?.insurancePolicy?.policyNumber,
    [WCCoveragesKey.PolicyType]: coverage?.insurancePolicy?.policyType,
    [WCCoveragesKey.PolicyStatusDate]: coverage?.insurancePolicy?.policyStatusDate,
    [WCCoveragesKey.InsuranceCarrier]: coverage?.insurancePolicy?.insuranceCarrier?.accountName,
    [WCCoveragesKey.CarrierID]: coverage?.insurancePolicy?.insuranceCarrier?.carrierId,
    [WCCoveragesKey.WrapUp]: coverage?.insurancePolicy?.wrapUp,
    [WCCoveragesKey.CreatedBy]: `${coverage?.createdUser?.firstName} ${
      coverage?.createdUser?.lastName
    }, ${formatDateTime(coverage?.createdAt)}`,
    [WCCoveragesKey.LastModifiedBy]:
      coverage?.updatedUser &&
      `${coverage?.updatedUser?.firstName} ${coverage?.updatedUser?.lastName}, ${formatDateTime(
        coverage?.updatedAt
      )}`,
  })),
});
