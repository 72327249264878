export enum PHCCoverageKey {
  Id = 'id',
  CoverageNumber = 'coverageNumber',
  EmployerId = 'employerId',
  EmployerDOLNUmber = 'employerDOLNumber',
  HCPlanType = 'hcPlanType',
  HCPlanName = 'hcPlanName',
  HCPlanId = 'hcPlanId',
  ActionTaken = 'actionTaken',
  PHCPlanNumber = 'phcPlanNumber',
  EffectiveDate = 'effectiveDate',
  HealthCarePlanName = 'healthCarePlanName',
  EmployerName = 'employerName',
  RawPlanGroupName = 'rawPlanGroupName',
  DOLNumber = 'dolNumber',
  StatusEffectiveDate = 'statusEffectiveDate',
  HealthCareContractor = 'healthCareContractor',
  HealthCareContractorId = 'healthCareContractorId',
  HealthCarePlanEmployerId = 'healthCarePlanEmployerId',
  HealthCarePlanEmployerLaborNumber = 'healthCarePlanEmployerLaborNumber',
  ContractorId = 'contractorId',
  Status = 'status',
  NewAction = 'newAction',
  StatusDate = 'statusDate',
  GroupNumber = 'groupNumber',
  EmployeeClass = 'employeeClass',
  CreatedBy = 'createdUser',
  UpdatedBy = 'updatedUser',
  CreatedAt = 'createdAt',
  CreatedUser = 'createdUser',
  UpdatedAt = 'updatedAt',
  UpdatedUser = 'updatedUser',
}
