import { Location } from 'history';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';

import { PATHS } from 'src/appConfig/paths';
import Navbar from 'src/components/Navbar';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, TenantService } from 'src/services';
import Dev from './Dev';

import Sidebar from 'src/components/Sidebar';

import AuthContainer from './StartupContainers/AuthContainer';
import ContentContainer from './StartupContainers/ContentContainer';
import DialogContainer from './StartupContainers/DialogContainer';
import NotFound from './StartupContainers/NotFound';
import SplashScreen from './StartupContainers/SplashScreen';
import ToastContainer from './StartupContainers/ToastContainer';

import { Box } from '@mui/material';
import { useComponentDidMount } from 'src/hooks';
import ScrollToTop from './StartupContainers/ScrollToTop';
import { useDispatch } from 'react-redux';
import { AppName } from 'src/appConfig/constants';
import { urlAppMap } from 'src/components/Sidebar/helpers';
import { LoadingCommon } from 'src/modules/shared-main/components';
import { setActiveApp, setShowSidebar } from 'src/redux/common/commonSlice';

const RelatedContactsViewAll = React.lazy(
  () => import('src/components/Contact/RelatedContactsViewAll')
);
const ContactsManagement = React.lazy(() => import('./ContactsManagement'));
const CustomSettingsManagement = React.lazy(() => import('./CustomSettingManagement'));

const RelatedAccountContactViewAll = React.lazy(
  () => import('./Related/RelatedAccountContactManagement/RelatedAccountContactViewAll')
);
const Dashboard = React.lazy(() => import('./Dashboard'));
const MyProfile = React.lazy(() => import('./MyProfile'));
const UserManagement = React.lazy(() => import('./UserManagement'));
const Profiles = React.lazy(() => import('./Profiles'));
const Groups = React.lazy(() => import('./Groups'));
const GroupDetail = React.lazy(() => import('./GroupDetail'));
const AccountManagement = React.lazy(() => import('./AccountManagement'));
const ProfileDetail = React.lazy(() => import('./ProfileDetail'));
const AssignedUsers = React.lazy(() => import('./AssignedUsers'));
const ObjectManagement = React.lazy(() => import('./ObjectPermission/ObjectManagement'));
const FieldAndTabManagement = React.lazy(() => import('./ObjectPermission/FieldAndTabManagement'));
const SystemPermissions = React.lazy(() => import('./SystemPermissions'));
const AppPermissions = React.lazy(() => import('./AppPermissions'));

const WC1Management = React.lazy(() => import('./WC1Management'));
const WC3AManagement = React.lazy(() => import('./WC3AManagement'));
const WC3Management = React.lazy(() => import('./WC3Management'));
const WC5Management = React.lazy(() => import('./WC5Management'));
const WC5AManagement = React.lazy(() => import('./WC5AManagement'));
const DCDCaseManagement = React.lazy(() => import('./DCDCaseManagement'));

const WC2Management = React.lazy(() => import('./WC2Management'));
const WC77Management = React.lazy(() => import('./WC77Management'));
const WC21Management = React.lazy(() => import('./WC21Management'));

const WCInsurancePoliciesManagement = React.lazy(() => import('./WCInsurancePoliciesManagement'));
const OrdersManagement = React.lazy(() => import('./OrdersManagement'));
const PrehearingForDelinquentManagement = React.lazy(
  () => import('./PrehearingForDelinquentManagement')
);

const TDI62Management = React.lazy(() => import('./TDI62Management'));
const TDI21Management = React.lazy(() => import('./TDI21Management'));
const PHCCoveragesManagement = React.lazy(() => import('./PHCCoveragesManagement'));
const HealthCarePlanManagement = React.lazy(() => import('./HealthCarePlanManagement'));
const TDI46Management = React.lazy(() => import('./TDI46Management'));
const TDI30Management = React.lazy(() => import('./TDI30Management'));
const TDI15Management = React.lazy(() => import('./TDI15Management'));
const TDICaseManagement = React.lazy(() => import('./TDICaseManagement'));
const DisbursementManagement = React.lazy(() => import('./DisbursementManagement'));
const VocationalRehabilitationManagement = React.lazy(
  () => import('./VocationalRehabilitationManagement')
);
const EmployerCoveragesFullView = React.lazy(
  () => import('./WCCoveragesManagement/EmployerCoveragesFullView')
);
const IPCoveragesFullView = React.lazy(() => import('./WCCoveragesManagement/IPCoveragesFullView'));
const TrackingLogInsurancePoliciesFullView = React.lazy(
  () => import('src/containers/TrackingLogManagement/TrackingLogInsurancePoliciesViewAll')
);
const Receipts = React.lazy(() => import('./ReceiptManagement'));
const HearingManagement = React.lazy(() => import('./HearingManagement'));
const AwardWorksheetManagement = React.lazy(() => import('./AwardWorksheetManagement'));
const TdiPlanManagement = React.lazy(() => import('./TdiPlanManagement'));
const GLAccountManagement = React.lazy(() => import('./GLAccountManagement'));
const DecisionManagement = React.lazy(() => import('./DecisionManagement'));
const TDISFCaseManagement = React.lazy(() => import('./TDISFCaseManagement'));

const HC4Management = React.lazy(() => import('./HC4Management'));
const HC15Management = React.lazy(() => import('./HC15Management'));
const HC61Management = React.lazy(() => import('./HC61Management'));
const ComplaintManagement = React.lazy(() => import('./ComplaintManagement'));
const EmployerAuditManagement = React.lazy(() => import('./EmployerAuditManagement'));
const RequestManagement = React.lazy(() => import('./RequestManagement'));

const WC14Management = React.lazy(() => import('./WC14Management'));

const FileManagement = React.lazy(() => import('./FileManagement'));

const ReportManagement = React.lazy(() => import('./ReportManagement'));
const PortalUserManagement = React.lazy(() => import('./PortalUserManagement'));

const Routing: React.FC<RoutingProps> = ({ onSetActiveApp, showSidebar, collapseSidebar }) => {
  const sidebarWidth = showSidebar ? (collapseSidebar ? 80 : 253) : 0;

  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowSidebar(true));
  }, [dispatch]);

  Navigator.setTopHistory(useHistory());

  useComponentDidMount(() => {
    onSetActiveApp(Navigator.getApp());
    const currentWebTenant = TenantService.getWebTenant();
    TenantService.setTenant({ name: currentWebTenant });
  });

  return (
    <Box pt={5} m={3} mr={1}>
      <Navbar location={location} />
      <Sidebar location={location} />
      <Suspense fallback={<LoadingCommon />}>
        <Box
          sx={{
            marginLeft: `${sidebarWidth}px`,
          }}
        >
          <Switch location={location}>
            <Route
              path={PATHS.root}
              render={() => (
                <Redirect to={PATHS.dashboard.replace(':app', urlAppMap[AppName.DCDEcms])} />
              )}
              exact
            />
            <CustomRoute pageRequiredAuth path={PATHS.dashboard} component={Dashboard} />
            <CustomRoute pageRequiredAuth path={PATHS.myProfile} component={MyProfile} />
            <CustomRoute pageRequiredAuth path={PATHS.userManagements} component={UserManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.profiles} component={Profiles} exact />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.profileDetail}
              component={ProfileDetail}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.profileAssignedUsers}
              component={AssignedUsers}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.phcCoverageDetail}
              component={PHCCoveragesManagement}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.ObjectPermission.object}
              component={ObjectManagement}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.ObjectPermission.field}
              component={FieldAndTabManagement}
              exact
            />
            <CustomRoute pageRequiredAuth path={PATHS.groups} component={Groups} exact />
            <CustomRoute pageRequiredAuth path={PATHS.groupDetail} component={GroupDetail} exact />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.customSettings}
              component={CustomSettingsManagement}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.profileSystem}
              component={SystemPermissions}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.appPermissions}
              component={AppPermissions}
              exact
            />
            <CustomRoute pageRequiredAuth path={PATHS.contacts} component={ContactsManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.relatedContact}
              component={RelatedContactsViewAll}
              exact
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.accountContacts}
              component={RelatedAccountContactViewAll}
              exact
            />
            <CustomRoute pageRequiredAuth path={PATHS.accounts} component={AccountManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.dcdCases} component={DCDCaseManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.hearings}
              component={HearingManagement}
              exact
            />
            <CustomRoute pageRequiredAuth path={PATHS.wc1} component={WC1Management} />
            <CustomRoute pageRequiredAuth path={PATHS.wc3a} component={WC3AManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.wc3} component={WC3Management} />
            <CustomRoute pageRequiredAuth path={PATHS.wc5a} component={WC5AManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.wc2} component={WC2Management} />
            <CustomRoute pageRequiredAuth path={PATHS.wc5a} component={WC5AManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.wc5} component={WC5Management} />
            <CustomRoute pageRequiredAuth path={PATHS.wc5} component={WC5Management} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.fromEmployerWcCoverages}
              component={EmployerCoveragesFullView}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.fromInsurancePolicyWcCoverages}
              component={IPCoveragesFullView}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.fromInsurancePoliciesTrackingLog}
              component={TrackingLogInsurancePoliciesFullView}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.wcInsurancePolicies}
              component={WCInsurancePoliciesManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.tdi30s} component={TDI30Management} />
            <CustomRoute pageRequiredAuth path={PATHS.tdi62} component={TDI62Management} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.healthCarePlans}
              component={HealthCarePlanManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.tdi15} component={TDI15Management} />
            <CustomRoute pageRequiredAuth path={PATHS.orderDetail} component={OrdersManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.prehearingForDelinquent}
              component={PrehearingForDelinquentManagement}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.disbursements}
              component={DisbursementManagement}
            />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.vocationalRehabilitation}
              component={VocationalRehabilitationManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.receipts} component={Receipts} />
            <CustomRoute pageRequiredAuth path={PATHS.hearings} component={HearingManagement} />
            <Route path={PATHS.dev} component={Dev} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.awardWorksheets}
              component={AwardWorksheetManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.tdiPlans} component={TdiPlanManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.tdiCases} component={TDICaseManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.glAccounts} component={GLAccountManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.decisions} component={DecisionManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.tdi46s} component={TDI46Management} />
            <CustomRoute pageRequiredAuth path={PATHS.tdi21s} component={TDI21Management} />
            <CustomRoute pageRequiredAuth path={PATHS.tdiSFCases} component={TDISFCaseManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.healthCarePlans}
              component={HealthCarePlanManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.hc4s} component={HC4Management} />
            <CustomRoute pageRequiredAuth path={PATHS.hc15s} component={HC15Management} />
            <CustomRoute pageRequiredAuth path={PATHS.hc61s} component={HC61Management} />
            <CustomRoute pageRequiredAuth path={PATHS.complaints} component={ComplaintManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.employerAudits}
              component={EmployerAuditManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.requests} component={RequestManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.portalUsers}
              component={PortalUserManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.uploadFiles} component={FileManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.reports} component={ReportManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.portalUsers}
              component={PortalUserManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.wc14s} component={WC14Management} />
            <CustomRoute pageRequiredAuth path={PATHS.wc77s} component={WC77Management} />
            <CustomRoute pageRequiredAuth path={PATHS.wc21s} component={WC21Management} />
            <CustomRoute path={PATHS.dev} component={Dev} />
            <Route component={NotFound} />
          </Switch>
        </Box>
      </Suspense>
      <ContentContainer />
      <AuthContainer />
      <DialogContainer />
      <ToastContainer />
      <ScrollToTop />
    </Box>
  );
};

type RoutingProps = ReturnType<typeof routeMapStateToProps> &
  typeof routeMapDispatchToProps & { location: Location };

const routeMapStateToProps = (state: IRootState) => ({
  showSidebar: state.common.showSidebar,
  collapseSidebar: state.common.collapseSidebar,
});

const routeMapDispatchToProps = {
  onSetActiveApp: setActiveApp,
};

export default connect(routeMapStateToProps, routeMapDispatchToProps)(Routing);

const CRouting: React.FC<Props> = ({ isAuthenticated, pageRequiredAuth, component, ...rest }) => {
  const renderRoute = (Component: any) => (props: RouteProps) => {
    if (isAuthenticated === null) return <SplashScreen />;

    if ((isAuthenticated && pageRequiredAuth) || (!isAuthenticated && !pageRequiredAuth)) {
      // Before render component, check permission first
      return <Component {...props} />;
    }

    const redirectPath = isAuthenticated ? PATHS.userManagements : PATHS.userManagements;
    const redirectProps = {
      to: {
        pathname: redirectPath,
        state: { from: props.location },
      },
    };
    return <Redirect {...redirectProps} />;
  };

  return <Route {...rest} render={renderRoute(component)} />;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { pageRequiredAuth?: boolean };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

const CustomRoute = connect(mapStateToProps, mapDispatchToProps)(CRouting);
