import { ReportId } from 'src/containers/ReportManagement/helpers';

export const PATHS = {
  root: '/',
  dev: '/dev',
  welcome: '/welcome',
  signIn: '/login',
  myAccount: '/my-accounts',
  dashboard: '/:app/dashboard',
  logout: '/:app/logout',

  // ======== Profile ========
  myProfile: '/me',
  changePassword: '/me/change-password',
  editMyProfile: '/me/edit',
  configureNotification: '/me/notification',

  // ======== User Management ========
  userManagements: '/:app/user-managements',
  addUser: '/:app/user-managements/add',
  userDetail: '/:app/user-managements/:id',

  // ======== Custom setting ========
  customSettings: '/:app/custom-settings',

  // ======== User Permissions ========
  profiles: '/:app/profiles',
  profileDetail: '/:app/profiles/:id',
  profileAssignedUsers: '/:app/profiles/:id/assigned-users',
  profileSystem: '/:app/profiles/:id/system-permissions',

  // ======== Groups Management ========
  groups: '/:app/groups',
  groupDetail: '/:app/groups/:id',

  // ======== Account Management ========
  accounts: '/:app/accounts',
  accountDetail: '/:app/accounts/:id',
  accountHierarchy: '/:app/accounts/:id/hierarchy',
  accountAddressHistory: '/:app/accounts/:id/address-history',
  relatedContact: '/:app/accounts/:id/related-contacts',
  accountRelatedDisbursements: '/:app/accounts/:id/disbursements',
  accountPHCCoverages: '/:app/accounts/:id/phc-coverages',
  accountPHCCoveragesHistory: '/:app/accounts/:id/phc-coverages-history',
  accountTDISFCases: '/:app/accounts/:id/tdi-sf-cases',
  accountCases: '/:app/accounts/:id/cases',
  accountRoutingInformation: '/:app/accounts/:id/routing-information',
  accountWCInsurancePolicies: '/:app/accounts/:id/wc-insurance-policies',
  accountPortalUsers: '/:app/accounts/:id/portal-users',
  accountComments: '/:app/accounts/:id/comments',

  // ======== TDI Coverages ========
  phcCoverageDetail: '/:app/phc-coverages/:id',

  // ======== TDI Coverages ========
  relatedTdiCoveragesViewAll: '/:app/accounts/:id/tdi-coverages',
  relatedTdiCoveragesHistoryViewAll: '/:app/accounts/:id/tdi-coverages-history',

  // ======== Employer Audit ========
  relatedEmployerAuditsViewAll: '/:app/accounts/:id/employer-audits',

  // ======== Contact Management ========
  contactDetail: '/:app/contacts/:id',

  // ======== Permission ========
  ObjectPermission: {
    object: '/:app/profiles/:id/object-permission',
    field: '/:app/profiles/:id/object-and-field/:object',
  },
  appPermissions: '/:app/profiles/:id/app-permissions',

  // ======== Contact ========
  contacts: '/:app/contacts',
  contactAddressHistories: '/:app/contacts/:id/:masterId/address-history',

  // ======== Related ========
  accountContacts: '/:app/accounts/:id/account-contacts',

  // ======== DCD Cases ========
  dcdCases: '/:app/dcd-cases',
  dcdCasesDetail: '/:app/dcd-cases/:id',
  dcdCasesRoutingInformation: '/:app/dcd-cases/:id/routing-information',
  dcdCaseInjuryIllness: '/:app/dcd-cases/:id/injury-illness',
  dcdCaseDependents: '/:app/dcd-cases/:id/case-dependents',
  relatedReceipts: '/:app/dcd-cases/:id/receipts',
  dcdSCFLedgers: '/:app/dcd-cases/:id/scf-ledgers',
  dcdCaseRoutingInformation: '/:app/dcd-cases/:id/routing-information',
  relatedDisbursements: '/:app/dcd-cases/:id/disbursements',
  dcdSCFScheduleOfDisbursement: '/:app/dcd-cases/:id/scf-schedule-of-disbursement',
  relatedPrehearingForEmployer: '/:app/dcd-cases/:id/prehearing-for-employer',
  relatedOrders: '/:app/dcd-cases/:id/orders',
  relatedCaseStatusHistories: '/:app/dcd-cases/:id/case-status-history',
  relatedAssociatedCases: '/:app/dcd-cases/:id/wc-associated-cases',
  relatedCaseDecisions: '/:app/dcd-cases/:id/decisions',
  dcdCaseProcessingLog: '/:app/dcd-cases/:id/case-processing-log',
  relatedWCCaseParty: '/:app/dcd-cases/:id/party-names-and-addresses',
  relatedHearings: '/:app/dcd-cases/:id/case-hearings',
  awardSummary: '/:app/dcd-cases/:caseId/award-summaries/:id',
  relatedWCCasePartyHistory: '/:app/dcd-cases/:id/party-names-and-addresses-history',

  // ======== Approval History DCD Case ========
  approvalHistoryDetail: '/:app/dcd-cases/:caseId/approval-histories/:id',
  relatedApprovalHistories: '/:app/dcd-cases/:id/approval-histories',
  relatedVendors: '/:app/dcd-cases/:id/vendors',
  relatedVocationalRehabilitation: '/:app/dcd-cases/:id/vocational-rehabilitation',
  relatedCaseAwards: '/:app/dcd-cases/:id/case-awards',
  relatedEmploymentAtTimeOfII: '/:app/dcd-cases/:id/employment-at-time-of-injury-illness',
  caseSettlements: '/:app/dcd-cases/:id/case-settlements',

  // ======== Case Settlement ========
  caseSettlementDetails: '/:app/dcd-cases/case-settlements/:caseSettlementId',
  relatedCaseSettlementApprovalHistory:
    '/:app/dcd-cases/case-settlements/:caseSettlementId/approval-histories/:id',
  relatedCaseSettlementApprovalHistories:
    '/:app/dcd-cases/case-settlements/:caseSettlementId/approval-histories',

  // ======== WC-1 ========
  wc1Detail: '/:app/wc-1/:id',
  wc1RoutingInformation: '/:app/wc-1/:id/routing-information',
  wc1: '/:app/wc-1',
  WC1InjuryIllness: '/:app/wc-1/:id/:wc1Id/injury-illness',
  WC1CaseDependents: '/:app/wc-1/:id/:wc1Id/case-dependents',
  WC1CaseProcessingLog: '/:app/wc-1/:id/case-processing-log',

  // ======== WC-3A ========
  wc3a: '/:app/wc-3a',
  wc3aDetail: '/:app/wc-3a/:id',

  // ======== WC-3 ========
  wc3: '/:app/wc-3',
  wc3Detail: '/:app/wc-3/:id',
  WC3CaseProcessingLog: '/:app/wc-3/:id/case-processing-log',

  // ======== WC Forms ========
  wc1Form: '/:app/dcd-cases/:id/wc1s',
  wc2Form: '/:app/dcd-cases/:id/wc2s',
  wc3aForm: '/:app/dcd-cases/:id/wc3as',
  wc5Form: '/:app/dcd-cases/:id/wc5s',
  wc5aForm: '/:app/dcd-cases/:id/wc5as',
  wc3Form: '/:app/dcd-cases/:id/wc3s',
  relatedWC14s: '/:app/dcd-cases/:id/wc-14s',
  relatedWC77s: '/:app/dcd-cases/:id/wc-77s',
  relatedAwardSummaries: '/:app/dcd-cases/:id/award-summaries',

  // ======== TDI Forms ========
  tdi46Form: '/:app/tdi-cases/:id/tdi46s',

  // ======== Case Dependents ========
  caseDependents: '/:app/dcd-cases/:id/case-dependents/:type/:wcId',

  // ======== WC-2 ========
  wc2: '/:app/wc-2',
  wc2Detail: '/:app/wc-2/:id',
  WC2InjuryIllness: '/:app/wc-2/:id/:wc2Id/injury-illness',

  // ======== WC-5 ========
  wc5Detail: '/:app/wc-5/:id',
  wc5RoutingInformation: '/:app/wc-5/:id/routing-information',
  wc5: '/:app/wc-5',
  wc5InjuryIllness: '/:app/wc-5/:id/:wc5Id/injury-illness',

  // ======== WC Insurance Policies ========
  wcInsurancePolicies: '/:app/wc-insurance-policies',
  wcInsurancePoliciesDetails: '/:app/wc-insurance-policies/:id',

  // ======== WC-Coverages ========
  fromEmployerWcCoverages: '/:app/account/:id/coverages',
  fromInsurancePolicyWcCoverages: '/:app/wc-insurance-policies/:id/coverages',

  // ======== WC-5A ========
  wc5a: '/:app/wc-5a',
  wc5aDetail: '/:app/wc-5a/:id',
  wc5aCaseDependents: '/:app/wc-5a/:id/:wc5aId/case-dependents',

  // ======== Vocational Rehabilitation ========
  vocationalRehabilitation: '/:app/dcd-cases/:wcCaseId/vocational-rehabilitation',
  detailVocationalRehabilitation: '/:app/dcd-cases/:wcCaseId/vocational-rehabilitation/:id',
  relatedVRProvider: '/:app/dcd-cases/:wcCaseId/vocational-rehabilitation/:id/vr-provider',
  relatedVRPartyNamesAddress:
    '/:app/dcd-cases/:wcCaseId/vocational-rehabilitation/:id/party-names-addresses',

  // ======== Orders ========
  orders: '/:app/orders',
  orderDetail: '/:app/orders/:id',
  ordersApprovalHistory: '/:app/orders/:id/approval-histories',
  ordersApprovalHistoryDetail: '/:app/orders/:orderId/approval-histories/:id',

  // ======== Prehearing for employer ========
  prehearingForDelinquent: '/:app/prehearing-for-employer',
  prehearingForDelinquentDetail: '/:app/prehearing-for-employer/:id',
  prehearingForDelinquentRoutingInformation:
    '/:app/prehearing-for-employer/:id/routing-information',

  // ======== Disbursements ========
  disbursements: '/:app/disbursements',
  disbursementDetail: '/:app/disbursements/:id',
  disbursementRoutingInformation: '/:app/disbursements/:id/routing-information',
  relatedDisbursementApprovalHistory: '/:app/disbursements/:id/approval-histories',
  disbursementApprovalHistoryDetail: '/:app/disbursements/:disbursementId/approval-histories/:id',

  // ======== Receipt ========
  receipts: '/:app/receipts',
  receiptDetail: '/:app/receipts/:id',
  receiptRoutingInformation: '/:app/receipts/:id/routing-information',
  relatedReceiptApprovalHistory: '/:app/receipts/:id/approval-histories',
  receiptApprovalHistoryDetail: '/:app/receipts/:receiptId/approval-histories/:id',

  // ======== Hearings ========
  hearings: '/:app/hearings',
  relatedHearingsRequiredDocuments: '/:app/hearings/:id/required-documents',
  hearingDetail: '/:app/hearings/:id',
  relatedHearingsTypePurpose: '/:app/hearings/:id/types-purpose',
  hearingCaseProcessingLogs: '/:app/hearings/:id/case-processing-log',
  hearingRelatedDecisions: '/:app/hearings/:id/decisions',
  hearingRelatedArchives: '/:app/hearings/:id/party-archives',
  hearingHRSHARs: '/:app/hearings/:id/hrs-har',

  // ======== TDI-62 ========
  tdi62: '/:app/tdi-62s',
  tdi62Detail: '/:app/tdi-62s/:id',
  tdi62RoutingInformation: '/:app/tdi-62s/:id/routing-information',

  // ======== TDI-15s ========
  tdi15: '/:app/tdi-15s',
  tdi15Detail: '/:app/tdi-15s/:id',
  tdi15RoutingInformation: '/:app/tdi-15s/:id/routing-information',
  tdi15Contacts: '/:app/tdi-15s/:id/contacts',

  // ======== TDI-21s ========
  tdi21s: '/:app/tdi-21s',
  tdi21: '/:app/tdi-21s/:id',

  // ======== Related Hearings ========
  relatedApprovalHistoryHearingViewAll: '/:app/hearings/:id/approval-histories',
  approvalHistoryHearingDetails: '/:app/hearings/:hearingId/approval-histories/:id',
  relatedHearingsPartyNamesAddress: '/:app/hearings/:id/party-names-addresses',
  relatedHearingNotes: '/:app/hearings/:id/related-notes',

  // ======== GL Account ========
  glAccounts: '/:app/gl-accounts',
  glAccountDetail: '/:app/gl-accounts/:id',
  glAccountReconcile: '/:app/gl-accounts/:id/reconcile',
  glAccountRollUp: '/:app/gl-accounts/:id/roll-up',

  // ======== Award Worksheet ========
  awardWorksheets: '/:app/award-worksheets',
  awardWorksheetDetail: '/:app/award-worksheets/:id',
  ppdInjuredBodyParts: '/:app/award-worksheets/:id/ppd-injured-body-parts',
  periodsOfTpdViewAll: '/:app/award-worksheets/:id/periods-of-tpd-disability',
  periodsOfTTDViewAll: '/:app/award-worksheets/:id/periods-of-ttd-disability',
  relatedAwardEmployments: '/:app/award-worksheets/:id/award-employments',
  awardWorksheetDependents: '/:app/award-worksheets/:id/dependents',

  // ======== Decision ========
  decisions: '/:app/decisions',
  decisionDetail: '/:app/decisions/:id',
  relatedDecisionTextBuilder: '/:app/decisions/:id/text-builder',
  decisionCaseProcessingLog: '/:app/decisions/:id/case-processing-log',
  decisionApprovalHistoryDetail: '/:app/decisions/:decisionId/approval-histories/:id',
  decisionApprovalHistory: '/:app/decisions/:id/approval-histories',
  decisionRoutingInformation: '/:app/decisions/:id/routing-information',
  decisionAmended: '/:app/decisions/:id/amended',
  decisionAppealed: '/:app/decisions/:id/appealed',
  decisionReopened: '/:app/decisions/:id/reopened',
  decisionRequestForReconsideration: '/:app/decisions/:id/request-for-reconsideration',
  decisionRelatedArchives: '/:app/decisions/:id/party-history-archives',
  relatedDecisionPartyNamesAddress: '/:app/decisions/:id/party-names-addresses',
  decisionAwardWorksheets: '/:app/decisions/:id/award-worksheets',

  // ======== Tdi Plan ========
  tdiPlans: '/:app/tdi-plans',
  tdiPlanDetail: '/:app/tdi-plans/:id',
  relatedTDIPlanEmployerAudits: '/:app/tdi-plans/:id/employer-audits',
  relatedTDIPlanTDICoverages: '/:app/tdi-plans/:id/tdi-coverages',

  // ======== TDI Cases ========
  tdiCases: '/:app/tdi-cases',
  tdiCase: '/:app/tdi-cases/:id',
  accountTDICase: '/:app/accounts/:id/tdi-case',
  tdiCasesCaseProcessingLog: '/:app/tdi-cases/:id/case-processing-log',
  tdiTextBuilder: '/:app/tdi-cases/:id/tdi-text-builder',
  relatedTDICasesHearings: '/:app/tdi-cases/:id/hearings',
  tdiCaseRoutingInformation: '/:app/tdi-cases/:id/routing-information',
  relatedTDICaseDecisions: '/:app/tdi-cases/:id/decisions',
  tdiCasePotentialAssociated: '/:app/tdi-cases/:id/potential-associated-wc-cases',
  tdiCasesPartyNames: '/:app/tdi-cases/:id/party-names-and-addresses',
  tdiCasePartyHistory: '/:app/tdi-cases/:id/party-names-and-addresses-history',

  // ======== TDI-46s ========
  tdi46sDetail: '/:app/tdi-46s/:id',
  tdi46s: '/:app/tdi-46s',

  // ======== TDI-30s ========
  tdi30: '/:app/tdi-30s/:id',
  tdi30s: '/:app/tdi-30s',

  // ======== TDISF Case ========
  tdiSFCase: '/:app/tdi-sf-cases/:id',
  tdiSFCases: '/:app/tdi-sf-cases',
  tdiSFCaseHearings: '/:app/tdi-sf-cases/:id/hearings',
  tdiSFCasesCaseProcessingLog: '/:app/tdi-sf-cases/:id/case-processing-log',
  relatedTDISFCaseDecisions: '/:app/tdi-sf-cases/:id/decisions',
  tdiSFCaseRoutingInformation: '/:app/tdi-sf-cases/:id/routing-information',
  tdiSFCaseRelatedTDI30s: '/:app/tdi-sf-cases/:id/tdi-30s',
  relatedTDISFDisbursements: '/:app/tdi-sf-cases/:id/disbursements',
  relatedTDISFPartyNames: '/:app/tdi-sf-cases/:id/party-names-and-addresses',
  relatedTDISFPartyHistory: '/:app/tdi-sf-cases/:id/party-names-and-addresses-history',

  // ======== HC-4s ========
  hc4s: '/:app/hc-4s',
  hc4: '/:app/hc-4s/:id',
  hc4RoutingInformation: '/:app/hc-4s/:id/routing-information',

  // ======== HC-15s ========
  hc15s: '/:app/hc-15s',
  hc15: '/:app/hc-15s/:id',
  hc15RoutingInformation: '/:app/hc-15s/:id/routing-information',
  hc15Accounts: '/:app/hc-15s/:id/accounts',
  hc15PHCCoverages: '/:app/hc-15s/:id/phc-coverages',

  // ======== Health Care Plan ========
  healthCarePlan: '/:app/health-care-plans/:id',
  healthCarePlans: '/:app/health-care-plans',

  // ======== HC-61s ========
  hc61s: '/:app/hc-61s',
  hc61: '/:app/hc-61s/:id',
  hc61RoutingInformation: '/:app/hc-61s/:id/routing-information',
  hc61Contacts: '/:app/hc-61s/:id/contacts',

  // ======== Requests ========
  requests: '/:app/requests',
  request: '/:app/requests/:id',
  requestRoutingInformation: '/:app/requests/:id/routing-information',

  // ======== Complaints ========
  complaints: '/:app/complaints',
  complaint: '/:app/complaints/:id',
  fraudComplaints: '/:app/complaints/fraud-complaints',
  fraudComplaint: '/:app/complaints/fraud-complaints/:id',
  fraudComplaintRoutingInformation: '/:app/complaints/fraud-complaints/:id/routing-information',
  phcTDIComplaints: '/:app/complaints/phc-tdi',
  phcTDIComplaint: '/:app/complaints/phc-tdi/:id',
  phcTDIComplaintRoutingInformation: '/:app/complaints/phc-tdi/:id/routing-information',

  // ======== Employer Audit ========
  employerAudits: '/:app/employer-audits',
  employerAudit: '/:app/employer-audits/:id',
  employerAuditPHCCoverages: '/:app/employer-audits/:id/phc-coverages',
  employerAuditWCCoverages: '/:app/employer-audits/:id/wc-coverages',
  employerAuditRoutingInformation: '/:app/employer-audits/:id/routing-information',

  uploadFiles: '/:app/upload-files',

  // ==============Tracking log============
  fromAccountTrackingLog: '/:app/accounts/:id/tracking-log',
  fromInsurancePoliciesTrackingLog: '/:app/wc-insurance-policies/:id/tracking-log',
  fromTDIPlanTrackingLog: '/:app/tdi-plans/:id/tracking-log',
  fromHeathCarePlanTrackingLog: '/:app/health-care-plans/:id/tracking-log',
  fromTDIComplaintTrackingLog: '/:app/complaints/phc-tdi/:id/tracking-log',
  fromFraudComplaintTrackingLog: '/:app/complaints/fraud-complaints/:id/tracking-log',
  fromTDI30TrackingLog: '/:app/tdi-30s/:id/tracking-log',
  fromDisbursementTrackingLog: '/:app/disbursements/:id/tracking-log',
  fromReceiptTrackingLog: '/:app/receipts/:id/tracking-log',
  fromEmployerAuditTrackingLog: '/:app/employer-audits/:id/tracking-log',
  fromVRProcessTrackingLog: '/:app/dcd-cases/:wcCaseId/vocational-rehabilitation/:id/tracking-log',

  // ======== Report ========
  reports: '/:app/reports',
  reportsDetail: '/:app/reports/:id',
  reportRejectedHCPDetail: `/:app/reports/${ReportId.RejectedPHCSelfInsurance}/:startDate/:endDate/`,
  reportApprovedHealthCarePlanDetail: `/:app/reports/${ReportId.ApprovedHealthCarePlans}/:planType/:startDate/:endDate/`,
  reportHC15sDetail: `/:app/reports/${ReportId.HC15s}/:certifiedAction/:startDate/:endDate/`,
  reportTDI21sAnnualReportDetail: `/:app/reports/${ReportId.TDI21sAnnualReportSummaryByPlanType}/:calendarYear/:typeOfPlan/`,
  reportTDI15SelfInsuredDetail: `/:app/reports/${ReportId.TDI15sTDISelfInsurerPlanReport}/:startDate/:endDate/:actionTaken/`,
  reportEmployerRegistrationActivitiesDetail: `/:app/reports/${ReportId.EmployerRegistrationActivities}/:startDate/:endDate/:dolType/`,
  reportTDI46ReconsiderationAppealsDetail: `/:app/reports/${ReportId.TDI46ReconsiderationsAppeals}/:startDate/:endDate/:tdi46ReconsiderationAppealType/`,
  reportTDI62sDetail: `/:app/reports/${ReportId.TDI62s}/:actionStatus/:startDate/:endDate/`,
  reportTDI30sDetail: `/:app/reports/${ReportId.TDI30s}/:startDate/:endDate/`,

  // ======== User Portal ========
  portalUser: '/:app/portal-users/:id',
  portalUsers: '/:app/portal-users',
  relatedAuditHistoryPortalUser: '/:app/portal-users/:id/audit-history',

  // ======== WC - 14s ========
  wc14s: '/:app/wc-14s',
  wc14: '/:app/wc-14s/:id',

  // ======== WC - 77s ========
  wc77: '/:app/wc-77s/:id',
  wc77s: '/:app/wc-77s',

  // ======== WC - 21s ========
  wc21s: '/:app/wc-21s/',
  wc21: '/:app/wc-21s/:id',
};

export const HIDE_NAV_PATHS = [];
