import { ALL_OPTIONS_VALUE } from 'src/modules/shared-main/components/Select';
import { UpLoadFormValue } from 'src/queries/DCDCases/helpers';
import { formatDate } from 'src/utils';
import { isEmpty } from 'src/validations';
import { DocumentCategory, InsuranceTypes } from './DocumentInfo/helpers';

export enum UploadKey {
  //Document Information
  Id = 'id',
  WCCaseId = 'wcCaseId',
  Insurance = 'insuranceType',
  DocumentCategory = 'documentCategory',
  DateOfInjury = 'dateOfInjury',
  FillingParty = 'fillingParty',
  DCDFillingDate = 'dcdFillingDate',
  DocumentType = 'documentType',
  DateDocumentReceived = 'dateDocumentReceived',
  DocumentDate = 'documentDate',
  DocumentDescription = 'documentDescription',
  Attachment = 'attachment',
  LinkTo = 'linkTo',
  TDICaseId = 'tdiCaseId',
  TDISFCaseId = 'tdiSFCaseId',
  DecisionId = 'decisionId',

  //Notification Information
  NotificationRecipientType = 'notificationRecipientType',
  RecipientUsers = 'recipientUserIds',
  IsAllRecipientUsers = 'isAllUser',
  RecipientProfiles = 'recipientProfileIds',
  IsAllRecipientProfiles = 'isAllProfile',
  Size = 'size',
  DocumentName = 'documentName',
  AttachmentURL = 'attachmentURL',
}

export const documentCategoryMapping = {
  [InsuranceTypes.WCEmployee]: DocumentCategory.Checklist,
  [InsuranceTypes.WCNonEmployee]: DocumentCategory.Form,
};

export const getDocumentCategoryValue = (insurance) => {
  // eslint-disable-next-line security/detect-object-injection
  return documentCategoryMapping[insurance];
};

export const toInitialValues = () => ({
  //Document Information
  [UploadKey.Insurance]: InsuranceTypes.WCEmployee,
  [UploadKey.DocumentCategory]: DocumentCategory.Checklist,
  [UploadKey.DateOfInjury]: null,
  [UploadKey.FillingParty]: null,
  [UploadKey.DCDFillingDate]: null,
  [UploadKey.DocumentType]: null,
  [UploadKey.DateDocumentReceived]: formatDate(new Date().toISOString()),
  [UploadKey.DocumentDate]: formatDate(new Date().toISOString()),
  [UploadKey.DocumentDescription]: '',
  [UploadKey.Attachment]: '',

  //Notification Information
  [UploadKey.RecipientUsers]: [],
  [UploadKey.RecipientProfiles]: [],
});

export const toSubmitData = (data, file, wcCaseId, linkTo): UpLoadFormValue => {
  const isAllRecipientUsers = data?.[UploadKey.RecipientUsers].includes(ALL_OPTIONS_VALUE);
  const isAllRecipientProfiles = data?.[UploadKey.RecipientProfiles].includes(ALL_OPTIONS_VALUE);

  const removedEmptyAndValidateDate = () => {
    const removedEmpty = Object.keys(data).reduce(
      (newData, key) =>
        isEmpty(data[key as string])
          ? newData
          : { ...newData, [key as string]: data[key as string] },
      {}
    );
    return {
      ...removedEmpty,
      [UploadKey.DateDocumentReceived]: new Date(
        data?.[UploadKey.DateDocumentReceived]
      ).toISOString(),
      [UploadKey.DocumentDate]: new Date(data?.[UploadKey.DocumentDate]).toISOString(),
      [UploadKey.LinkTo]: linkTo,

      [UploadKey.RecipientUsers]: isAllRecipientUsers ? [] : data?.[UploadKey.RecipientUsers],
      [UploadKey.IsAllRecipientUsers]: isAllRecipientUsers,

      [UploadKey.RecipientProfiles]: isAllRecipientProfiles
        ? []
        : data?.[UploadKey.RecipientProfiles],
      [UploadKey.IsAllRecipientProfiles]: isAllRecipientProfiles,
    };
  };

  return {
    ...removedEmptyAndValidateDate(),
    size: file.size,
    attachmentURL: file.url,
    documentName: file.name,
    [UploadKey.WCCaseId]: wcCaseId,
  };
};

export type FileType = {
  name?: string;
  url?: string;
  size?: string;
};
